/*--------------------------------Buttons-------------------------------*/
.btn-auto {
    background: rgba(var(--primary-color),1) !important;
    color: rgba(var(--primary-font-color),1) !important;
}
.btn-auto:hover,
.btn-auto:focus {
    background: rgba(var(--primary-dark-shade),1) !important;
    color: rgba(var(--primary-font-color),1) !important;
}
.btn-auto:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary-color),0.25) !important;
}

.btn-secondary-auto {
    background: rgba(var(--secondary-color),1) !important;
    color: rgba(var(--secondary-font-color),1) !important;
}
.btn-secondary-auto:hover,
.btn-secondary-auto:focus {
    background: rgba(var(--secondary-dark-shade),1) !important;
    color: rgba(var(--secondary-font-color),1) !important;
}
.btn-secondary-auto:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--secondary-color),0.25) !important;
}
.btn-outline-secondary-auto {
  color: rgba(var(--secondary-color),1) !important;
  border-color: rgba(var(--secondary-color),1) !important;
  /* color: rgba(var(--secondary-font-color),1) !important; */
}
.btn-outline-secondary-auto:hover,
.btn-outline-secondary-auto:focus {
  background: rgba(var(--secondary-dark-shade),1) !important;
  color: rgba(var(--secondary-font-color),1) !important;
}
.btn-outline-secondary-auto:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-color),0.25) !important;
}
/*--------------------------------Buttons-------------------------------*/
/*--------------------------------Inputs--------------------------------*/
.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary-color),0.25) !important;
    outline: 1px solid rgba(var(--primary-color),0.25) !important;
}
/*--------------------------------Inputs--------------------------------*/
/*--------------------------------Carousel-------------------------------*/
.carousel-image .carousel-item {
    height: 550px;
    background-size: 100% !important;
    background-position: center center !important;
}
.carousel-image .carousel-item .carousel-item-image-wrapper {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3)
}
@media (max-width: 992px) {
    .carousel-image .carousel-item {
        height: 500px;
    }
}
@media (max-width: 768px) {
    .carousel-image .carousel-item {
        height: 350px;
    }
}
@media (max-width: 576px) {
    .carousel-image .carousel-item {
        height: 200px;
    }
}
/*--------------------------------Carousel-------------------------------*/
/*--------------------------------Utilities-------------------------------*/
a {
    color: rgba(var(--primary-color),1);
}
a:hover {
    color: rgba(var(--primary-dark-shade),1);
}
p {
    text-align: justify;
}
.bg-auto {
    background: rgba(var(--primary-color),1);
}
.bg-secondary-auto {
    background: rgba(var(--secondary-color),1);
}
.text-auto {
    color: rgba(var(--primary-color),1);
}
.text-secondary-auto {
    color: rgba(var(--secondary-color),1);
}
.heading {
    position: relative;
    margin-bottom: 10px;
    display: inline-block;
    text-transform: uppercase;
}
.heading:after {
    content: '';
    background: rgba(var(--secondary-color),1);
    /* border: 0.5px solid rgba(var(--secondary-color),1);; */
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
}
h2.heading {
    font-size: 22px;
    font-weight: bold;
}
.border-secondary-auto {
  border-color: rgba(var(--secondary-color),1) !important;
}
/*--------------------------------Utilities-------------------------------*/
